import { CircularProgress } from "@mui/material";

const Loader = ()=> {
    return <div>
        <div className="loader" style={{display: 'flex'}} >
            <CircularProgress sx={{color: '#ED1C24'}}></CircularProgress>
        </div>
    </div>
}

export default Loader;