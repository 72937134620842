import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import './css/cart.css';
import './css/terms.css';
import './css/allProducts.css';
import './css/singleProduct.css';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux'
import store from './context/Context.js';
import {lazy, Suspense} from 'react';
import Loader from './components/Loader';
const App = lazy(()=> import('./App'));

ReactDOM.render(
  <Suspense fallback={<Loader/>}>
    <Provider store={store}>
      <App />
    </Provider>
  </Suspense>,
  document.getElementById('root'));